<template>
  <div class='homebg' :style="{width: fullWidth+'px', height:fullHeight+'px'}">
    <div class="img_box" :style="{width: fullWidth+'px'}"></div>
    <div class='logo'></div>
    <div class="loginBody-image">
      <van-image
        width="4rem"
        height="4rem"
        :src="require('../../assets/img/login.png')"
      />
    </div>
    <div class="loginBody-text">
      <h1>{{$t('webnames')}}</h1>
      <h2>{{$t('webnames2')}}</h2>
      <h3>{{$t('webnames3')}}</h3>
    </div>
    <van-button type="primary" to="/login">立即进入</van-button>
  </div>
</template>

<script>
export default {
  name: 'Index',
  data () {
    return {
      fullWidth: document.documentElement.clientWidth,
      fullHeight: document.documentElement.clientHeight
    }
  },
  methods: {
    handleResize () {
      this.fullWidth = document.documentElement.clientWidth
      this.fullHeight = document.documentElement.clientHeight
    }
  },
  created () {
    window.addEventListener('resize', this.handleResize)
  },
  beforeUnmount () {
    window.removeEventListener('resize', this.handleResize)
  }
}
</script>

<style lang="less" scoped>
.van-button {
  position: fixed;
  bottom: 10%;
  width: 300px;
  left: 50%;
  transform:translateX(-50%);
  height: 2.66667rem;
  font-size: 0.93333rem;
  color: #ffffff;
  background-color: #0055ff;
  border-radius: 1.6rem;
  border: none;
}

.loginBody-image {
  float: left;
}

.loginBody-text {
  position: absolute;
  color: #fff;
  text-align: center;
  width: 100%;
  top: 30%;
  padding: 0 5%;
  h1 {
    font-size: 35px;
  };
  h2 {
    margin-top: 1%;
    font-size: 35px;
    font-weight: 200;
  };
  h3 {
    margin-top: 3%;
    font-size: 20px;
    font-weight: 200;
  }
}

.homebg {
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
  .img_box {
    position: absolute;
    background-image: url('../../assets/img/home_bg.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    width: 101%;
    height: 101%;
  }
}
</style>
